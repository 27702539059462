@import "../../assets/theme/index.scss";

header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    max-width: 100vw;
    background: $white;

    .nav {
   
        background: $navy-blue;
        min-height: 40px;
        width: 100%;
        padding: 0 5%;

        .container {
            position: relative;

            button, a {
                position: absolute;
                display: flex;
                align-items: center;
                height: 40px;
                text-decoration: none;
            }

   

            .goback {
                left: 0;
                font-size: 1.2rem;

                i {
                    font-size: 0.7rem;
                    margin-right: 0.5rem;
                }
            }

            .weblink {
                right: 0;

                i {
                    font-size: 0.7rem;
                    margin-left: 0.5rem;
                }
            }
        }

      
    }


    .logo {
        max-height: 32px;
        margin: 1.25rem 0 2.5rem;
    }

    .groupe-loccitane {
        max-height: 64px;
    }
}