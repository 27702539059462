@import "../../assets/theme/index.scss";

.container {
    @media screen and (max-width: 540px) {
        padding: 0 5%;
    }
}

.home {
    max-width: 100vw;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .groupDescription {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
        margin: 6rem 0;
        max-height: 1000px;
        transition: 0.3s;

        &__text {
            flex: 1;
            padding-right: 5%;
            min-width: 300px;
            font-size: large;

            a {
                font-size: large;
                color: $black;

                &:hover {
                    text-decoration: underline;
                    transition: 0.3s;
                }
            }
        }

        iframe {
            width: 100%;
        }

        h1 {
            margin-bottom: 1.6rem;
        }

        iframe {
            flex: 1;
        }

        &--hidden {
            max-height: 0;
            overflow: hidden;
            transition: 0.6s;
            margin: 0;
        }

    
    }
}

.expandButton {
    .a-icon-arrow-right {
        transform: rotateY(90);
    }

    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    box-shadow: 0px 1px 4px 0.2px $light-gray;
    max-width: 30px;
    max-height: 30px;
    min-width: unset;
    padding: 1rem;
    border-radius: 50%;
}

.brands {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 2% 5%;
    margin-bottom: 2rem;
    background: $narvik;
    max-height: 1000px;
    transition: 0.3s;
    position: relative;

    &--hidden {
        max-height: 4px;
        transition: 0.6s;
        padding: 0;
        margin: 1rem;
        overflow: visible;
    }

    .expandButton {
        position: absolute;
        bottom: -15px;
        left: 50%;
    }

    .brands__banner {
        position: relative;
        margin-bottom: 1rem;
        max-height: 500px;
        overflow: auto;
        transition: 0.6s;

        &--hidden {
            max-height: 0;
            overflow: hidden;
            transition: 0.6s;
        }

        img {
            height: 250px;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }

        p {
            position: absolute;
            bottom: 4rem;
            left: 4rem;
            color: $white;
            font-size: 3.5rem;
            margin: 0;
        }
    }

    @media screen and (max-width: 500px) {
        padding: 0;

        .brands__row {
            margin: 2% 5%;
        }
        
    }


    .brands__row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 2rem;
        width: 100%;
    }

    .brand {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;

        img {
            max-height: 22px;
        }
    }
}

footer {
    max-width: 100vw;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 5% 1rem;

    .container-notice {
        display: flex;
        font-size: 1.6rem;
        font-style: italic;
        text-align: left;
        flex-wrap: wrap;

        a {
            font-size: 1.6rem;
            &:hover {
                text-decoration: underline;
            }
        }
    }

}

  
  .container-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    width: 100vw;
    padding: 3rem 5% 1rem;
    font-size: 1.2rem;

    #ot-sdk-btn {
        background-color: unset;
        text-decoration: underline;
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
        color: black !important;
        font-size: 1.2rem !important;
        min-width: unset !important;
    }

    .container-footer-images {
        display: flex;
        align-items: center;

        img {
            max-height: 100px;
            margin: 1rem 2rem;
        }
        
    }
  }
  
  
  
  