@import "./assets/theme/index.scss";

:root {
  max-width: 100vw;
  --primaryColor: #ffcb00;
  
}

body {
  margin: 0;
  font-family: LOccitaneSans,system-ui,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-size: 62.5%;
  --bs-body-font-size: 62.5%;
  &.scroll-locked {
      position: fixed;
      overflow-y: scroll;
      height: 100%;
      -webkit-overflow-scrolling: auto;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

.a--tabfocus {
  outline: 0.2rem dotted $primary;
  outline-offset: 0.1rem;
}

body {
  width: 100%;
  height: 100%;
  background: none repeat scroll 0 0 $white;
  color: $primary;
  font-family: $sans-serif;
  font-size: 1.4rem;
}

a {
  overflow: hidden;
  font-size: 1.2rem;
  color: $fjord;
  text-decoration: none;
}

fieldset {
  border: 0 none;
  margin: 0;
  padding: 0;
  min-width: 0;
}

table {
  width: 100%;
}

th {
  text-align: left;
}

td {
  padding: 1em;
  vertical-align: top;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

figure {
  margin: 0;
  max-width: 100%;
}

picture {
  margin: 0;
  font-size: 0;
  max-width: 100%;
}

img {
  height: auto;

  &[loading=lazy] {
      opacity: 1;
      transition: opacity 700ms;
      transition-delay: 0.5s;

      &[src=""][srcset=""],
      &[src=""]:not([srcset]),
      &:not([src])[srcset=""],
      &:not([src]):not([srcset]) {
          opacity: 0;
      }
  }
}

.desktop-hidden {
  @include media(desktop) {
      display: none !important;
  }
}

.mobile-hidden {
  @include media(phone-tablet) {
      display: none !important;
  }
}

.xs-store-locator {
  .m-header-user {
      @include media(phone-small) {
          display: none !important;
      }
  }
}

.xs-my-account {
  .m-header-storelocator {
      @include media(phone-small) {
          display: none !important;
      }
  }
}

body.touch .js-touch-hover {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.pagination {
  width: auto;
  margin: 4.8rem auto;
  text-align: center;

  .results-hits {
      @include visually-hidden();
  }

  li {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      text-align: center;
      vertical-align: top;
      line-height: 2.5rem;

      a {
          display: block;
      }

      &.current-page {
          font-weight: $bold;
      }
  }
}

.text-center {
  text-align: center;
}

.js--sticky {
  position: fixed;
}
.js--sticky-bottom {
  position: absolute;
  bottom: 6.4rem;
}

.loading {
  @keyframes loaderRotate {
      0% {
          transform: translate(-50%, -50%) rotate(0);
      }
      70%, 100% {
          transform: translate(-50%, -50%) rotate(1turn);
      }
  }

  &::before {
      content: '';
      position: fixed;
      font-size: 0;
      color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $overlay-regional-color;
      z-index: 105;
  }
  
  &:not(.regional-loading)::before {
      background: $overlay-global-color;
  }

  &::after {
      content: '';
      position: fixed;
      top: 50%;
      left: 50%;
      width: 4.8rem;
      height: 4.8rem;
      background: url(./assets/img/loader.svg) no-repeat center;
      background-size: contain;
      z-index: 106;
      animation-duration: 1.4s;
      animation-name: loaderRotate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
  }

  &.regional-loading {
      &::before {
          position: absolute;
          z-index: 2;
      }

      &::after {
          position: absolute;
          z-index: 3;
          width: 3.2rem;
          height: 3.2rem;
      }

      &.small-loading::after {
          width: 2.4rem;
          height: 2.4rem;
      }

      &.medium-loading::after {
          width: 4.4rem;
          height: 4.4rem;
      }

      &.big-loading::after {
          width: 4.8rem;
          height: 4.8rem;
      }

      &.ui-dialog {
          &::before {
              z-index: 102;
          }

          &::after {
              z-index: 103;
          }
      }
  }

  &.regional-loading:not(.ui-dialog) {
      position: relative;
  }
}

/* Placeholders */
.plh_rating {
  /* Used to prevent CLS in PLP rating snippets */
  min-height: 2rem;
}


