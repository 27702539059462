@mixin icon() {
	font-family: $icons !important;
	speak: none;
	font-style: normal;
	font-weight: $regular;
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.a-icon,
[class^="a-icon-"],
[class*=" a-icon-"] {
    @include icon();
}

.a-icon-address:before { content: "\ea01"; }
.a-icon-amazon:before { content: "\ea02"; }
.a-icon-arrow-left:before { content: "\ea03"; }
.a-icon-arrow-return-m:before { content: "\ea04"; }
.a-icon-arrow-return:before { content: "\ea05"; }
.a-icon-arrow-right:before { content: "\ea06"; }
.a-icon-auto-replenishment:before { content: "\ea07"; }
.a-icon-bag-full:before { content: "\ea08"; }
.a-icon-bag-received:before { content: "\ea09"; }
.a-icon-bag:before { content: "\ea0a"; }
.a-icon-beauty-profile:before { content: "\ea0b"; }
.a-icon-bike-m:before { content: "\ea0c"; }
.a-icon-cac_store:before { content: "\ea0d"; }
.a-icon-cac_store_express:before { content: "\ea0e"; }
.a-icon-calendar:before { content: "\ea0f"; }
.a-icon-car-m:before { content: "\ea10"; }
.a-icon-card-m:before { content: "\ea11"; }
.a-icon-card:before { content: "\ea12"; }
.a-icon-careers:before { content: "\ea13"; }
.a-icon-checkbox-v-m:before { content: "\ea14"; }
.a-icon-checkbox-v-s:before { content: "\ea15"; }
.a-icon-checkbox-v-xs:before { content: "\ea16"; }
.a-icon-checkbox-v:before { content: "\ea17"; }
.a-icon-chevron-down-m:before { content: "\ea18"; }
.a-icon-chevron-down-s:before { content: "\ea19"; }
.a-icon-chevron-down-xs:before { content: "\ea1a"; }
.a-icon-chevron-down:before { content: "\ea1b"; }
.a-icon-chevron-left-m:before { content: "\ea1c"; }
.a-icon-chevron-left-s:before { content: "\ea1d"; }
.a-icon-chevron-left-xs:before { content: "\ea1e"; }
.a-icon-chevron-left:before { content: "\ea1f"; }
.a-icon-chevron-right-m:before { content: "\ea20"; }
.a-icon-chevron-right-s:before { content: "\ea21"; }
.a-icon-chevron-right-xs:before { content: "\ea22"; }
.a-icon-chevron-right:before { content: "\ea23"; }
.a-icon-chevron-up-m:before { content: "\ea24"; }
.a-icon-chevron-up-xs:before { content: "\ea25"; }
.a-icon-chevron-up:before { content: "\ea26"; }
.a-icon-click-collect:before { content: "\ea27"; }
.a-icon-close-m:before { content: "\ea28"; }
.a-icon-close-s:before { content: "\ea29"; }
.a-icon-close-xs:before { content: "\ea2a"; }
.a-icon-close:before { content: "\ea2b"; }
.a-icon-crown:before { content: "\ea2c"; }
.a-icon-delivered:before { content: "\ea2d"; }
.a-icon-direction-arrow-m:before { content: "\ea2e"; }
.a-icon-error-framed-m:before { content: "\ea2f"; }
.a-icon-error-framed:before { content: "\ea30"; }
.a-icon-exit:before { content: "\ea31"; }
.a-icon-eye-hide-m:before { content: "\ea32"; }
.a-icon-eye-hide:before { content: "\ea33"; }
.a-icon-eye-show-m:before { content: "\ea34"; }
.a-icon-facebook-m:before { content: "\ea35"; }
.a-icon-facebook:before { content: "\ea36"; }
.a-icon-fav-full:before { content: "\ea37"; }
.a-icon-fav-on:before { content: "\ea38"; }
.a-icon-fav:before { content: "\ea39"; }
.a-icon-female:before { content: "\ea3a"; }
.a-icon-friendreferral:before { content: "\ea3b"; }
.a-icon-fullsizeproduct:before { content: "\ea3c"; }
.a-icon-geoloc-arrow:before { content: "\ea3d"; }
.a-icon-geoloc-m:before { content: "\ea3e"; }
.a-icon-geoloc:before { content: "\ea3f"; }
.a-icon-gift:before { content: "\ea40"; }
.a-icon-google:before { content: "\ea41"; }
.a-icon-hours-m:before { content: "\ea42"; }
.a-icon-hours-s:before { content: "\ea43"; }
.a-icon-hours:before { content: "\ea44"; }
.a-icon-icon-profile:before { content: "\ea45"; }
.a-icon-info-m:before { content: "\ea46"; }
.a-icon-instagram-m:before { content: "\ea47"; }
.a-icon-instagram:before { content: "\ea48"; }
.a-icon-label:before { content: "\ea49"; }
.a-icon-less-m:before { content: "\ea4a"; }
.a-icon-less-s:before { content: "\ea4b"; }
.a-icon-less-xs:before { content: "\ea4c"; }
.a-icon-less:before { content: "\ea4d"; }
.a-icon-linkedin:before { content: "\ea4e"; }
.a-icon-locator-airport:before { content: "\ea4f"; }
.a-icon-locator-flower:before { content: "\ea50"; }
.a-icon-locator-m:before { content: "\ea51"; }
.a-icon-locator-store:before { content: "\ea52"; }
.a-icon-locator-store2:before { content: "\ea53"; }
.a-icon-locator:before { content: "\ea54"; }
.a-icon-mail-m:before { content: "\ea55"; }
.a-icon-mail:before { content: "\ea56"; }
.a-icon-male:before { content: "\ea57"; }
.a-icon-map-dual-arrow-m:before { content: "\ea58"; }
.a-icon-mask:before { content: "\ea59"; }
.a-icon-more-m:before { content: "\ea5a"; }
.a-icon-more-s:before { content: "\ea5b"; }
.a-icon-more-xs:before { content: "\ea5c"; }
.a-icon-more:before { content: "\ea5d"; }
.a-icon-order-delivered:before { content: "\ea5e"; }
.a-icon-order-history:before { content: "\ea5f"; }
.a-icon-order-prepared:before { content: "\ea60"; }
.a-icon-order-returned:before { content: "\ea61"; }
.a-icon-order-sent:before { content: "\ea62"; }
.a-icon-password:before { content: "\ea63"; }
.a-icon-payment:before { content: "\ea64"; }
.a-icon-paypal:before { content: "\ea65"; }
.a-icon-phone-m:before { content: "\ea66"; }
.a-icon-phone-s:before { content: "\ea67"; }
.a-icon-phone:before { content: "\ea68"; }
.a-icon-pinterest:before { content: "\ea69"; }
.a-icon-point-relay:before { content: "\ea6a"; }
.a-icon-preparation:before { content: "\ea6b"; }
.a-icon-pro-partner:before { content: "\ea6c"; }
.a-icon-profil:before { content: "\ea6d"; }
.a-icon-question:before { content: "\ea6e"; }
.a-icon-refil:before { content: "\ea6f"; }
.a-icon-refine-m:before { content: "\ea70"; }
.a-icon-return:before { content: "\ea71"; }
.a-icon-reviews:before { content: "\ea72"; }
.a-icon-reward-card:before { content: "\ea73"; }
.a-icon-rotate-m:before { content: "\ea74"; }
.a-icon-rotate:before { content: "\ea75"; }
.a-icon-search-m:before { content: "\ea76"; }
.a-icon-search:before { content: "\ea77"; }
.a-icon-sent:before { content: "\ea78"; }
.a-icon-share:before { content: "\ea79"; }
.a-icon-shipping-box:before { content: "\ea7a"; }
.a-icon-shipping:before { content: "\ea7b"; }
.a-icon-signs:before { content: "\ea7c"; }
.a-icon-star-0:before { content: "\ea7d"; }
.a-icon-star-m-0:before { content: "\ea7e"; }
.a-icon-star-m-100:before { content: "\ea7f"; }
.a-icon-star-m-25:before { content: "\ea80"; }
.a-icon-star-m-50:before { content: "\ea81"; }
.a-icon-star-m-75:before { content: "\ea82"; }
.a-icon-star-s-0:before { content: "\ea83"; }
.a-icon-star-s-100:before { content: "\ea84"; }
.a-icon-star-s-25:before { content: "\ea85"; }
.a-icon-star-s-50:before { content: "\ea86"; }
.a-icon-star-s-75:before { content: "\ea87"; }
.a-icon-star:before { content: "\ea88"; }
.a-icon-store-m:before { content: "\ea89"; }
.a-icon-store:before { content: "\ea8a"; }
.a-icon-tiktok:before { content: "\ea8b"; }
.a-icon-time:before { content: "\ea8c"; }
.a-icon-train-m:before { content: "\ea8d"; }
.a-icon-twitter:before { content: "\ea8e"; }
.a-icon-upload-m:before { content: "\ea8f"; }
.a-icon-upload:before { content: "\ea90"; }
.a-icon-validate:before { content: "\ea91"; }
.a-icon-validation-framed-m:before { content: "\ea92"; }
.a-icon-validation-framed:before { content: "\ea93"; }
.a-icon-vkontakte:before { content: "\ea94"; }
.a-icon-walk-m:before { content: "\ea95"; }
.a-icon-warning-filled:before { content: "\ea96"; }
.a-icon-warning-framed-m:before { content: "\ea97"; }
.a-icon-warning-framed:before { content: "\ea98"; }
.a-icon-without-m:before { content: "\ea99"; }
.a-icon-youtube:before { content: "\ea9a"; }

$a-icon-address: "\ea01";
$a-icon-amazon: "\ea02";
$a-icon-arrow-left: "\ea03";
$a-icon-arrow-return-m: "\ea04";
$a-icon-arrow-return: "\ea05";
$a-icon-arrow-right: "\ea06";
$a-icon-auto-replenishment: "\ea07";
$a-icon-bag-full: "\ea08";
$a-icon-bag-received: "\ea09";
$a-icon-bag: "\ea0a";
$a-icon-beauty-profile: "\ea0b";
$a-icon-bike-m: "\ea0c";
$a-icon-cac_store: "\ea0d";
$a-icon-cac_store_express: "\ea0e";
$a-icon-calendar: "\ea0f";
$a-icon-car-m: "\ea10";
$a-icon-card-m: "\ea11";
$a-icon-card: "\ea12";
$a-icon-careers: "\ea13";
$a-icon-checkbox-v-m: "\ea14";
$a-icon-checkbox-v-s: "\ea15";
$a-icon-checkbox-v-xs: "\ea16";
$a-icon-checkbox-v: "\ea17";
$a-icon-chevron-down-m: "\ea18";
$a-icon-chevron-down-s: "\ea19";
$a-icon-chevron-down-xs: "\ea1a";
$a-icon-chevron-down: "\ea1b";
$a-icon-chevron-left-m: "\ea1c";
$a-icon-chevron-left-s: "\ea1d";
$a-icon-chevron-left-xs: "\ea1e";
$a-icon-chevron-left: "\ea1f";
$a-icon-chevron-right-m: "\ea20";
$a-icon-chevron-right-s: "\ea21";
$a-icon-chevron-right-xs: "\ea22";
$a-icon-chevron-right: "\ea23";
$a-icon-chevron-up-m: "\ea24";
$a-icon-chevron-up-xs: "\ea25";
$a-icon-chevron-up: "\ea26";
$a-icon-click-collect: "\ea27";
$a-icon-close-m: "\ea28";
$a-icon-close-s: "\ea29";
$a-icon-close-xs: "\ea2a";
$a-icon-close: "\ea2b";
$a-icon-crown: "\ea2c";
$a-icon-delivered: "\ea2d";
$a-icon-direction-arrow-m: "\ea2e";
$a-icon-error-framed-m: "\ea2f";
$a-icon-error-framed: "\ea30";
$a-icon-exit: "\ea31";
$a-icon-eye-hide-m: "\ea32";
$a-icon-eye-hide: "\ea33";
$a-icon-eye-show-m: "\ea34";
$a-icon-facebook-m: "\ea35";
$a-icon-facebook: "\ea36";
$a-icon-fav-full: "\ea37";
$a-icon-fav-on: "\ea38";
$a-icon-fav: "\ea39";
$a-icon-female: "\ea3a";
$a-icon-friendreferral: "\ea3b";
$a-icon-fullsizeproduct: "\ea3c";
$a-icon-geoloc-arrow: "\ea3d";
$a-icon-geoloc-m: "\ea3e";
$a-icon-geoloc: "\ea3f";
$a-icon-gift: "\ea40";
$a-icon-google: "\ea41";
$a-icon-hours-m: "\ea42";
$a-icon-hours-s: "\ea43";
$a-icon-hours: "\ea44";
$a-icon-icon-profile: "\ea45";
$a-icon-info-m: "\ea46";
$a-icon-instagram-m: "\ea47";
$a-icon-instagram: "\ea48";
$a-icon-label: "\ea49";
$a-icon-less-m: "\ea4a";
$a-icon-less-s: "\ea4b";
$a-icon-less-xs: "\ea4c";
$a-icon-less: "\ea4d";
$a-icon-linkedin: "\ea4e";
$a-icon-locator-airport: "\ea4f";
$a-icon-locator-flower: "\ea50";
$a-icon-locator-m: "\ea51";
$a-icon-locator-store: "\ea52";
$a-icon-locator-store2: "\ea53";
$a-icon-locator: "\ea54";
$a-icon-mail-m: "\ea55";
$a-icon-mail: "\ea56";
$a-icon-male: "\ea57";
$a-icon-map-dual-arrow-m: "\ea58";
$a-icon-mask: "\ea59";
$a-icon-more-m: "\ea5a";
$a-icon-more-s: "\ea5b";
$a-icon-more-xs: "\ea5c";
$a-icon-more: "\ea5d";
$a-icon-order-delivered: "\ea5e";
$a-icon-order-history: "\ea5f";
$a-icon-order-prepared: "\ea60";
$a-icon-order-returned: "\ea61";
$a-icon-order-sent: "\ea62";
$a-icon-password: "\ea63";
$a-icon-payment: "\ea64";
$a-icon-paypal: "\ea65";
$a-icon-phone-m: "\ea66";
$a-icon-phone-s: "\ea67";
$a-icon-phone: "\ea68";
$a-icon-pinterest: "\ea69";
$a-icon-point-relay: "\ea6a";
$a-icon-preparation: "\ea6b";
$a-icon-pro-partner: "\ea6c";
$a-icon-profil: "\ea6d";
$a-icon-question: "\ea6e";
$a-icon-refil: "\ea6f";
$a-icon-refine-m: "\ea70";
$a-icon-return: "\ea71";
$a-icon-reviews: "\ea72";
$a-icon-reward-card: "\ea73";
$a-icon-rotate-m: "\ea74";
$a-icon-rotate: "\ea75";
$a-icon-search-m: "\ea76";
$a-icon-search: "\ea77";
$a-icon-sent: "\ea78";
$a-icon-share: "\ea79";
$a-icon-shipping-box: "\ea7a";
$a-icon-shipping: "\ea7b";
$a-icon-signs: "\ea7c";
$a-icon-star-0: "\ea7d";
$a-icon-star-m-0: "\ea7e";
$a-icon-star-m-100: "\ea7f";
$a-icon-star-m-25: "\ea80";
$a-icon-star-m-50: "\ea81";
$a-icon-star-m-75: "\ea82";
$a-icon-star-s-0: "\ea83";
$a-icon-star-s-100: "\ea84";
$a-icon-star-s-25: "\ea85";
$a-icon-star-s-50: "\ea86";
$a-icon-star-s-75: "\ea87";
$a-icon-star: "\ea88";
$a-icon-store-m: "\ea89";
$a-icon-store: "\ea8a";
$a-icon-tiktok: "\ea8b";
$a-icon-time: "\ea8c";
$a-icon-train-m: "\ea8d";
$a-icon-twitter: "\ea8e";
$a-icon-upload-m: "\ea8f";
$a-icon-upload: "\ea90";
$a-icon-validate: "\ea91";
$a-icon-validation-framed-m: "\ea92";
$a-icon-validation-framed: "\ea93";
$a-icon-vkontakte: "\ea94";
$a-icon-walk-m: "\ea95";
$a-icon-warning-filled: "\ea96";
$a-icon-warning-framed-m: "\ea97";
$a-icon-warning-framed: "\ea98";
$a-icon-without-m: "\ea99";
$a-icon-youtube: "\ea9a";
