@mixin make-button() {
	cursor: pointer;
	background-color: $sunglow;
	border: none;
	color: $primary;
	display: inline-block;
	font-weight: $medium;
	font-size: 1.4rem;
	line-height: 2.4rem;
	padding: 0.8rem 1.6rem;
	min-width: 12rem;
	max-width: 100%;
	text-align: center;
	transition: background-color 0.2s $cubic;
	box-sizing: border-box;

	body:not(.touch) &:hover,
	body:not(.touch) a:hover &,
	body:not(.touch) .js--content-full-link:hover & {
		background-color: $dandelion;
	}

	&[aria-disabled=true],
	&.a-button--disabled {
		cursor: default;
		border: 0.1rem solid $fjord;
		background: $narvik;
		color: $fjord;
		line-height: 2.2rem;
	}
}

@mixin make-button-text() {
	padding: 0;
	width: auto;
	border: none;
	min-width: 0;
	line-height: 1.6rem;
	font-weight: $medium;
	color: $dark-green;
	text-transform: none;
	text-decoration: underline;
	background-color: transparent;

	body:not(.touch) &:hover,
	body:not(.touch) a:hover & {
		background-color: transparent;
		text-decoration: underline;
	}
}

@mixin make-button-black-border() {
	@include make-button();
	background: $white;
	border: 0.1rem solid $primary;
	line-height: 2.2rem;

	body:not(.touch) &:hover,
	body:not(.touch) a:hover &,
	body:not(.touch) .js--content-full-link:hover & {
		background-color: $narvik;
	}

	&[aria-disabled=true],
	&.a-button--disabled {
		cursor: default;
		border: 0.1rem solid $light-gray;
		background: $narvik;
		color: $light-gray;
	}
}

@mixin make-tag() {
	height: 4rem;
	line-height: 4rem;
	font-size: 1.2rem;
	font-weight: $medium;
	margin: 0 .8rem 1.6rem;

	@include media(phone-tablet) {
		margin: 0 .2rem .4rem;
    }
}

@mixin no-underline() {
	&,
	body:not(.touch) &:hover,
	body:not(.touch) a:hover & {
		text-decoration: none;
	}
}

.a-button,
button,
input[type="button"],
input[type="submit"] {
	@include make-button();
}

.a-button--medium {
	@include make-button();
	min-width: 24rem;
}

.a-button--text {
	@include make-button-text();
}

.a-button--large {
	@include make-button();

	width: 100%;
	font-size: 1.35em;
	text-transform: uppercase;
}

.a-button--full-width {
	@include make-button();

	padding-left: 0;
	padding-right: 0;
	text-align: center;
	width: 100%;
}

.a-button--white {
	@include make-button();

	background: $white;
	border-color: transparent;
}

.a-button--border {
	@include make-button();

	background: transparent;
	border: 0.2rem solid $sunglow;
	line-height: 2rem;
}

.a-button--black-border {
	@include make-button-black-border();
}

.a-button--gray-border {
	@include make-button();

	background: transparent;
	color: $gray-60;
	border: 0.1rem solid $gray-30;
	line-height: 2.2rem;
}

.a-button--black {
	@include make-button();

	background: $dark-green;
	color: $white;
	border-color: transparent;

	body:not(.touch) &:hover,
	body:not(.touch) a:hover &,
	body:not(.touch) .js--content-full-link:hover & {
		background: $dim-gray;
	}
}

.a-button--disabled {
	background: $narvik;
	color: $light-gray;
	display: inline-block;
	font-weight: $medium;
	font-size: 1.4rem;
	line-height: 2.2rem;
	padding: .8rem 1.6rem;
	min-width: 12rem;
	max-width: 100%;
	text-align: center;
	transition: background-color 0.2s $cubic;
	box-sizing: border-box;
	border: .1rem solid $light-gray;

	body:not(.touch) &:hover,
	body:not(.touch) a:hover &,
	body:not(.touch) .js--content-full-link:hover &,
	.js--content-full-link:active &,
	.js--content-full-link:focus &,
	&:focus,
	&:active,
	&.a--tabfocus,
	.a--tabfocus & {
		background: $narvik;
	}
}

.a-button--grey {
	color: $fjord;
	background-color: $white-smoke;
	border-color: transparent;
}

.a-button--select {
	.a-icon {
		font-size: 1.2rem;
		line-height: 1.6rem;
		display: inline-block;
		width: 1.6rem;
		vertical-align: text-bottom;
		margin-right: 0.4rem;
	}

	.js--select {
		display: block;
	}

	.js--unselect {
		display: none;
	}

	&[aria-checked=true] {
		background: $primary !important;
		color: $white;

		.js--select {
			display: none;
		}

		.js--unselect {
			display: block;
		}


		body:not(.touch) &:hover,
		&.a--tabfocus {

			.a-icon::before {
				content: $a-icon-close-s;
			}
		}
	}
}

.dw-apple-pay-button {
	&,
	&:hover,
	&:focus,
	&:active {
		border-radius: 0;
		margin: 1rem 0 0 0;
		height: 4rem;
		width: 100%;
		background-image: -webkit-named-image(apple-pay-logo-black);
		background-color: $floral-white;
	}

	&:disabled {
		pointer-events: none;
	}
}

.a-paypal-button {
	@include make-button-black-border();

	img {
		height: 2rem;
		margin: 0.1rem 0;
		vertical-align: top;
	}

	&[aria-disabled=true],
	&.a-button--disabled {
		img {
			opacity: 0.6;
		}
	}
}

