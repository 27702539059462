
.searchForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;

  

    .searchForm__globalSearch {
        display: flex;
        align-items: flex-end;

        .m-field-wrapper {
            flex: 4;
        }

        .searchForm__submit {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            flex: 1;
            font-weight: 500;
    
            i {
                font-size: 1.8rem;
                margin-right: 1rem;
            }
        }
    
        .searchForm__toggleFilter {
            margin-left: 1rem;
            height: 40px;
            font-weight: 500;
            

            i {
                display: none;
            }
        }

        .searchForm__submit, .searchForm__toggleFilter {
            p {
                margin: 0;
              
            }
    
            @media screen and (max-width: 600px) {
                min-width: unset;
                padding: 0;
    
                i {
                    display: block;
                    margin-right: 0;
                }
                p {
                    display: none;
                }
            }
        }

    }


    .searchForm__advanced {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        margin: 0 auto;
        gap: 1.5rem;

        max-height: 0;
        overflow: hidden;
        transition: .5s max-height;

        &--open {
            max-height: 500px;
            transition: .5s max-height;
        }

        .m-field-wrapper {
            flex: 1;
        }
        
        @media screen and (max-width: 500px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
}