@mixin make-standard-input() {
	border: 0;
    box-sizing: border-box;
    font-weight: $regular;
    padding: 1rem 0.8rem;
    text-indent: 0;
    width: 100%;
    background-color: $white;
    -webkit-appearance: none;
    border-radius: 0;
    border: 0.1rem solid $light-gray;

    &:focus {
        border-width: 0.2rem;
        padding: 0.9rem 0.7rem;
    }

    &:-webkit-autofill {
        box-shadow: 0 0 0 3rem $white inset;
        -webkit-box-shadow: 0 0 0 3rem $white inset;
    }

    // provide Iphone zoom focus
    @include media(phone){
        font-size: 1.6rem;
    }
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

.a-input,
textarea,
input[type=date],
input[type=number],
input[type=password],
input[type=email],
input[type=tel],
input[type=text] {
    @include make-standard-input();
}

input[type=password] {
    font: caption;
    font-size: 1.6rem;
    font-family: sans-serif;
    letter-spacing: 0.1rem;

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
    }
}

select {
    background-color: $white; // set background color to make height work
    width: 100%;
    height: 3.2rem;
    border: 0;

    .mobile-device .form-field &:not(.variation-select) {
        display: block !important;
        position: absolute;
        z-index: 1;
        border: 0.1rem solid $light-gray;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 1.6rem;
        padding-right: 3.4rem;
    }

    &.error {
        ~ .ui-selectmenu-button {
            .ui-selectmenu-text  {
                padding-right: 6rem;
            }

            &::before {
                @include icon-size(md);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 4rem;
                @include icon();
                content: $a-icon-error-framed-m;
                color: $venetian-red;
            }

            &[aria-expanded=true] {
                &::before {
                    right: 3.9rem;
                }
            }
        }
    }
}

.form-field {
    position: relative;

    + .a-caption-message {
        margin-bottom: 0;
    }
}

.form-caption {
    @include make-col-ready();
    @include make-col(12);
    height: 2rem;
    line-height: 2rem;
    margin-bottom: 2rem;
}

.a-success-form,
.a-success-form p {
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $olive;
    text-align: center;
}

.a-error-form {
    display: block;
    margin: 0 0 0.8rem 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $venetian-red;
    font-weight: $medium;
}

.m-form-row {
    width: 100%;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    position: relative;
    margin-bottom: 1.6rem;
    vertical-align: top;

    select, .ui-selectmenu-button, .ui-menu-item {
        cursor: pointer;
    }

    &:not([class*=col-]) {
        flex: 0 0 70%;
        max-width: 70%;

        @include media(phone-tablet) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @include media(phone-tablet) {
        padding-left: ($small-gutter-width / 2);
        padding-right: ($small-gutter-width / 2);
    }

    &.label-space-before {
        padding-top: 2.6rem;

        &.checkbox,
        &.radio {
            padding-top: 3.8rem;
        }
    }


    .m-field-wrapper {
        position: relative;

        &.valid-field,
        &.error-field {
            input[type=text],
            input[type=email],
            input[type=password] {
                padding-right: 3.2rem;
            }

            .form-field {
                &::before {
                    @include icon-size(md);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0.8rem;
                    @include icon();
                }
            }
        }

        &.valid-field .form-field {
            &::before {
                content: $a-icon-validation-framed-m;
                color: $rich-olive;
            }
        }

        &.error-field .form-field {
            &::before {
                content: $a-icon-error-framed-m;
                color: $venetian-red;
            }
        }
    }

    &.checkbox,
    &.radio {
        .m-field-wrapper.valid-field .form-field,
        .m-field-wrapper.error-field .form-field {
            &::before,
            &::after {
                content: none !important;
            }
        }
    }

    &.password .m-field-wrapper.valid-field,
    .o-password-reset-dialog & .m-field-wrapper.valid-field,
    .returning-customers &.username .m-field-wrapper.valid-field,
    .m-field-wrapper.js--autofilled.valid-field,
    .m-field-wrapper.js--autofilled.error-field {
        .form-field::before,
        .form-field::after {
            content: none !important;
        }

        input[type=text],
        input[type=email],
        input[type=password] {
            padding-right: 0.8rem;
        }

        span.ui-selectmenu-button span.ui-selectmenu-text {
            padding-right: 3.2rem;
        }
    }

    &.date-field .m-field-wrapper {
        &.valid-field,
        &.error-field {
            input[type=text] {
                padding-right: 0;
            }
        }
    }

    .form-caption {
        display: none;
        font-size: 1.1rem;
        line-height: 1.8rem;
    }
    .a-birthday-error-message,
    .a-error-message {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $venetian-red;
        font-weight: $medium;
        display: block;
        text-align: left;
        margin: 0.8rem 0 0;

        &.empty {
            margin: 0;
        }
    }

    .a-warning-message {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $rich-gold;
        font-weight: $medium;
        display: block;
        text-align: left;
        margin: 0.8rem 0 0;
    }

    .a-caption-message,
    .a-caption-message2 {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: $medium;
        color: $fjord;
        display: block;
        text-align: left;
        margin: 0.8rem 0 0;
    }

    .a-mandatory-text {
        color: $fjord;
        font-family: $sans-serif;
        font-size: 1.2rem;
        font-weight: $medium;
        letter-spacing: 0;
        line-height: 1.6rem;
    }

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=tel] {
        line-height: 2rem;
        color: $light-gray;
        font-weight: $regular;
        height: 4rem;

        &.valid {
            color: $primary;
            border-color: $primary;

            &:focus {
                border-color: $light-gray;
            }
        }

        &[disabled=true],
        &[readonly] {
            color: $primary;
            border-color: $light-gray;
            background: $narvik;

            &:focus {
                border-color: $light-gray;
                border-width: .1rem;
                padding: 1rem .8rem;
            }
        }

        &.error ~ .a-caption-message {
            display: none;
        }

        // webkit
  
        &:focus::-webkit-input-placeholder {
            color: $dim-gray;
        }
 
        &:focus:-ms-input-placeholder {
            color: $dim-gray;
        }
     
        &:focus::-moz-placeholder {
            color: $dim-gray;
        }

        &.text-uppercase {
            text-transform: uppercase;
        }
    }

    label,
    .a-label {
        font-weight: $medium;
        line-height: 2rem;
        display: block;
        margin-bottom: 0.8rem;

        .sub {
            font-size: 0.9rem;
        }

        + .a-caption-message {
            margin-bottom: 0.8rem;
        }
    }

    .a-bar {
        display: none;
    }

    &.form-row-button {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 2.4rem;

        button {
            min-width: 24rem;
            display: block;
            margin: 0 auto;
        }
    }

    &.full {
        flex: 0 0 100%;
        max-width: 100%;
    }

    &.birthday,
    &.m-creditcard-date {
        margin-bottom: 0.8rem;
        @include media(tablet-desktop) {
            margin-bottom: 1.6rem;
            .row .m-form-row {
                margin-bottom: 0.8rem;
            }
        }

        .a-legend {
            font-weight: $medium;
            line-height: 2rem;
            display: inline-block;
            margin-bottom: 0.8rem;
        }

        .a-label {
            @include visually-hidden();
        }

        @include media(phone-tablet) {
            margin-bottom: 1.5rem;
        }

        .row {
            margin-right: -($grid-gutter-width / 2);
            margin-left: -($grid-gutter-width / 2);

            @include media(phone-tablet) {
                margin-right: -($small-gutter-width / 2);
                margin-left: -($small-gutter-width / 2);
            }
        }

        .a-error-form {
            position: relative;
        }
    }

    &.countries_states {
        margin-bottom: 0;

        .row {
            margin-left: -($grid-gutter-width / 2);
            margin-right: -($grid-gutter-width / 2);

            @include media(phone-tablet) {
                margin-left: -($small-gutter-width / 2);
                margin-right: -($small-gutter-width / 2);
            }
        }

        @include media(phone-tablet) {
            min-height: 0;
        }
    }


}

.a-label-required {
    margin: 0.5rem 0 2.4rem;
    color: $dim-gray;
    font-size: 1.1rem;
}

.a-error-summary {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $venetian-red;
    font-weight: $medium;
    display: block;
    text-align: left;
    margin: 0 0 1.6rem;
}

.m-form-row.paragraph {
    min-height: 0;

    &.fill {
        padding-bottom: 1.1rem;
    }

    p {
        margin: 0 auto 2rem;
        font-size: 1.3rem;
        line-height: 2rem;

        a {
            font-size: 1.3rem;
        }
    }
}

.m-form-row.visually-hidden {
    position: absolute;
    flex: 0 1 0.1rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    height: 0.1rem;
    max-height: 0.1rem;
    vertical-align: top;
    min-height: 0.1rem;
}

.m-form-row.select {
    display: inline-block;

    .m-field-wrapper {
        position: static;

        &.valid-field,
        &.error-field {
            .form-field {
                &::before,
                &::after {
                    right: 4rem;
                    z-index: 1;
                }
            }

            span.ui-selectmenu-button span.ui-selectmenu-text {
                padding-right: 5.6rem;
            }
        }
    }
}

.m-form-row.checkbox,
.m-form-row.radio {
    min-height: 0;
    padding-bottom: 1.1rem;
    &:not(.radio-nopadding-bottom) {
        margin-bottom: 1.1rem;
    }

    &.inline-option {
        margin-bottom: 0;

        .m-radio-wrap,
        .m-field-wrapper {
            display: inline-block;
            margin: 0 2.4rem 0.8rem 0;
        }

        input[type="radio"] + label {
            margin-bottom: 0;
        }
    }
}

.radio,
.fake-radio {
    max-height: inherit;

    input[type="radio"] {
        top: 0.3rem;
        padding: 0;
        z-index: -1;
        position: absolute;
        -webkit-appearance: none;
        width: 1.6rem;
        height: 1.6rem;
        opacity: 0;

        + label {
            margin-bottom: 1.6rem;
        }

        &.a--tabfocus {
            opacity: 1;
            z-index: 10;
            outline: 0 dotted $primary;
            outline-offset: 0.1rem;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                opacity: 0;
            }

            ~ label {
                &::before {
                    top: 50%;
                    left: -0.25rem;
                    width: 2.1rem;
                    height: 2.1rem;
                    border-radius: 50%;
                    box-shadow: inset 0 0 0 0.2rem $white, inset 0 0 0 0.3rem $tertiary;
                    border: 0.1rem dashed $tertiary;
                }
            }
        }
        &:disabled ~ label{
            color:  $gray-60;

            &::after{
                opacity: .6;
                border-color: $gray-10;
            }
            &::before{
                background-color: $narvik;
                border-color: $gray-10;
            }
            a{
                color:  $gray-60;
            }
        }
    }

    label,
    &.fake-radio {
        top: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        font-size: 1.4rem;
        font-weight: $medium;
        line-height: 2rem;
        padding-left: 3.2rem;
        pointer-events: all;
        color: $primary;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: inline-block;
        }

        &::before {
            top: 50%;
            left: 0;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            box-shadow: 0 0 0 0.1rem $tertiary;
            transform: translateY(-50%);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                box-shadow: none;
                border: 0.1rem solid $tertiary;
            }
        }
        &::after {
            top: 50%;
            left: 0.4rem;
            width: 0.8rem;
            height: 0.8rem;
            background: $primary;
            border-radius: 50%;
            transform: scale(0) translateY(-50%);
            background-color: $gray;
            transition: all .2s $cubic;
        }

        a {
            text-decoration: underline;
        }
    }

    input[type=radio]:checked ~ label::after,
    &.fake-radio.checked::after {
        transform: scale(1) translateY(-50%);
        top: 50%;
    }

    &.error {
        label {
            margin-bottom: 0.8rem;
            font-size: 1.1rem;
            line-height: 1.5rem;
            color: $venetian-red;
        }
    }
}

.checkbox,
.fake-checkbox {
    position: relative;
    vertical-align: top;

    input[type="checkbox"] {
        padding: 0;
        opacity: 1;
        z-index: -1;
        position: absolute;
        top: 50%;
        width: 1.6rem;
        height: 1.6rem;
        -webkit-appearance: none;
        transform: translateY(-50%);

        &.a--tabfocus {
            opacity: 1;
            z-index: 10;
            outline: 0.2rem dotted $primary;
            outline-offset: 0.2rem;
        }
        &:disabled ~ label{
            color:  $gray-60;

            &::after{
                opacity: .6;
                border-color: $gray-10;
            }
            &::before{
                background-color: $narvik;
                border-color: $gray-10;
            }
            a{
                color:  $gray-60;
            }
        }

    }

    label,
    &.fake-checkbox {
        top: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        font-size: 1.4rem;
        font-weight: $medium;
        line-height: 2rem;
        padding-left: 3.2rem;
        pointer-events: all;
        color: $primary;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
        }

        &::before {
            left: 0;
            width: 1.6rem;
            height: 1.6rem;
            border: 0.1rem solid $tertiary;
            transform: translateY(-50%);
        }

        &::after {
            content: "";
            left: 0.9rem;
            width: 1.6rem;
            height: 1.6rem;
            background: $primary url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI3cHgiIHZpZXdCb3g9IjAgMCAxMCA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1NC4xICg3NjQ5MCkgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAyPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IjIuLUNvbXBvbmVudHMvRm9ybXMvQ2hlY2tib3gvRmlsbGVkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMy4wMDAwMDAsIC00LjAwMDAwMCkiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIxLjIiPgogICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlBhdGgtMiIgcG9pbnRzPSI0IDcuNDEyODY2NzMgNi43OTM1NjY2NCAxMC4yMDY0MzM0IDEyIDUiPjwvcG9seWxpbmU+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=) center no-repeat;
            text-align: center;
            transform: scale(0) translate(-0.9rem, -0.9rem);
            transition: all .2s $cubic;
            transform-origin: 0 0;
            background-size: 1rem 0.8rem;
        }

        a {
            text-decoration: underline;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: $medium;
            color: $primary;
        }
    }

    input[type=checkbox]:checked ~ label::after,
    &.fake-checkbox.checked::after {
        transform: scale(1) translate(-0.9rem, -0.8rem);
    }

    &.error {
        label {
            margin-bottom: 0.8rem;
            font-size: 1.1rem;
            line-height: 1.5rem;
            color: $venetian-red;
            pointer-events: all;
        }
    }
}

.m-form-select-wrapper {
    padding: 0 0.8rem;
    border: 0.1rem solid $light-gray;
}

// SELECT
select,
span.ui-selectmenu-button {
    display: block;
    height: 4rem;
    font-size: 1.4rem;
    width: 100% !important;
    overflow: visible;
    background: $white;
    position: relative;

    span.ui-selectmenu-text {
        padding: 1rem 3.2rem 1rem 0;
        color: $light-gray;
        font-weight: $regular;
    }

    select.valid + & span.ui-selectmenu-text {
        color: $primary;
    }

    &[aria-expanded=true] {
        border-width: 0.2rem;
        padding: 0 0.7rem;

        span.ui-selectmenu-text {
            padding: 0.9rem 3.2rem 0.9rem 0
        }

        .ui-icon {
            right: 1.1rem;

            &::after {
                transform: rotate(180deg) translateY(50%);
            }
        }
    }

    .mobile-device & .ui-icon {
        z-index: 2;
        pointer-events: none;
    }
}

select.valid ~ span.ui-selectmenu-button:not([aria-expanded=true]) {
    color: $primary;
    border-color: $primary;
}

.ui-selectmenu-menu {
    z-index: 110;

    .ui-menu {
        @include scrollbar();
        overflow: auto;
        background-color: $white;
        border: 0.1rem solid $light-gray;
        max-height: 25.6rem;
        margin-top: -0.1rem;
        -webkit-overflow-scrolling: touch;

        .ui-menu-item {
            background-color: $white;
            color: $fjord;
            font-size: 1.4rem;
            line-height: 3.6rem;
            padding: 0 0.8rem 0 0.8rem;
            margin: 0;
            font-weight: $regular;
            display: flex;
            justify-content: space-between;
            border-top: 0.1rem solid $gray-10;

            &:first-child {
                border-top: 0;
            }

            body:not(.touch) &:hover,
            &.ui-state-focus {
                background-color: $narvik;
                color: $primary;
            }

            &.current {
                font-weight: $medium;
                background-color: $narvik;
                color: $primary;
            }

            &.unavailable {
                color: $shady-lady;
            }

            &[aria-disabled=true]{
                color: $nobel;
            }

            .opt-img {
                display: flex;
                flex-grow: 0;
                align-items: center;
                margin-left: -0.8rem;
                max-width: 3.2rem;
            }

            .opt-text {
                flex-grow: 1;
                text-align: left;
            }

            .opt-msg {
                flex-grow: 0;
                text-align: right;
                margin-left: 2rem;
            }
        }
    }
}

// HIDING DISABLED OPTIONS
.select2--hide-option {
    &.select2-container--default .select2-results > .select2-results__options .select2-results__option {
        &[aria-disabled=true]{
            display: none;
        }
    }
}

.m-subscription-box{
    &.checkbox{
        &.paragraph{
            .m-form-row{
                .a--tabfocus {
                    opacity: 1;
                    z-index: 10;
                    outline: 0.2rem dotted $primary;
                    outline-offset: 0.2rem;
                    margin-top: -0.1rem;

                    ~ label{
                        &::before{
                            border-radius: 0;
                            box-shadow: 0;
                            width: 1.6rem;
                            height: 1.6rem;
                            border: 0.1rem solid $primary;
                            left: 0;
                        }
                    }
                }

                label{
                    &::before{
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.date-field {
    legend {
        @include sr-only();
    }

    .form-field {
        border: 0.1rem solid $light-gray;
        padding: 0.8rem 0.8rem 0;
        height: 4rem;
    }

    .valid-field .form-field {
        border-color: $primary;
    }

    input[type=text] {
        border: 0;
        width: 4rem;
        vertical-align: middle;
        text-align: center;
        padding: 0;
        height: auto;

        &[readonly] {
            opacity: 0.5;
            background: $white-smoke;
        }

        &.year {
            width: 4.8rem;
        }

        &::-webkit-input-placeholder {
            color: $dim-gray;
        }
        &:-ms-input-placeholder {
            color: $dim-gray;
        }
        &::-moz-placeholder {
            color: $dim-gray;
        }
    }

    .a-sep {
        display: inline-block;
        padding: 0 0.2rem;
        vertical-align: middle;
        cursor: default;
    }
}

.m-btn-group-append {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 0.8rem;

    @include media(desktop) {
        flex-direction: row;
    }

    .form-field {
        flex-grow: 1;
        margin-bottom: 0.8rem;
    }

    .m-btn-append {
        margin-bottom: 0.8rem;

        &:disabled {
            color: $fjord;
            border-color: $light-gray;
            background-color: $narvik;
        }
    }

    .a-birthday-error-message,
    .a-error-message {
        margin-bottom: 0.8rem;
        flex-basis: 100%;

        @include media(mobile-tablet) {
            margin-top: 0;
        }
    }

    .a-button--black-border[aria-disabled=true] {
        border-color: $light-gray;
    }

    .m-shipping-parceltracking & {
        .a-error-message {
            margin-top: 0;
            margin-bottom: 0.8rem;

            @include media(desktop) {
                margin-bottom: 0;
            }
        }

        + .a-error-message {
            margin-top: -0.8rem;
            margin-bottom: 0.8rem;
        }
    }
}

.m-btn-group-append--inline {
    flex-direction: row;

    .form-field {
        margin-bottom: 0;
    }

    .m-btn-append.a-button {
        order: 0;
        margin-top: 0;
        margin-left: 0.4rem;
        min-width: 0;

        @include media(desktop) {
            margin-left: 0.8rem;
        }
    }
}

.m-btn-append.a-button {
    order: 1;
    min-width: 0;
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    @include media(desktop) {
        order: 0;
        min-width: 0;
        margin-top: 0;
        margin-left: 0.8rem;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
}