.jobList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    margin: 2rem 0;
    gap: 2rem;

    .jobCard {
        flex-basis: 48%;
    }



    @media screen and (max-width: 789px) {
        flex-direction: column;

        .jobCard {
            flex-basis: 100%;
        }
    }
}

.searchWrapper {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noResult {
    width: 100%;
    font-size: 2rem;
    margin: 2rem 0 10rem;
}