.jobPage {
    max-width: 100vw;
    width: 100vw;

    
    .jobContent {
        display: flex;
        flex-direction: column;
        width: 100vw;
        min-height: 100vh;

        header {
            flex: 0 1 auto;
            z-index: 2;
            position: static;
        }

        #searchPageIframe {
            flex: 1 1 auto;
            position: static;
            margin-top: -160px;
            z-index: 1;


            @media screen and (min-width:381px) and (max-width: 481px) {
                margin-top: -50px;
            }

            @media screen and (max-width: 381px) {
                margin-top: -100px;
            }
        }
    }
    




}