// mixins
@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.clearfix {
    @include clearfix();
}

// colors
// (names taken from http://www.color-blindness.com/color-name-hue/)

$primary: #001022;
$gray: #01172d;
$gray-blue: #031129;
$olive: #637127;
$west-side: #e5863d;
$chocolate: #b85e19;
$floral-white: #fffefa;
$nobel: #9a9a9a;
$shady-lady: #979797;
$iron: #e5e7e8;
$corn-silk: #fff7da;
$romance: #fcfbf8;
$china-ivory: #fcf4d7;
$denim: #1269cc;
$navy-blue: #1C1F2A;;

$black: #000;
$white: #fff;
$dark-green: #000f23;
$narvik: #f7f6f3;
$narvik-true: $narvik; // to prevent overriding of color on other variables.scss (SKY)
$sunglow: #ffcb00;
$dandelion: #ffdb4c;
$white-smoke: #ecebeb;
$dim-gray: #767676;
$light-gray: #73748b;
$very-light-gray: #ccc;
$concrete: #d3d0cb;
$rich-gold: #AF4D23;
$rich-olive: #3B7902;
$wood: #654949;

$tertiary: #73748B;

// Forms color :
$casper: #b2b6b8;
$fjord: #495460;
$venetian-red: #d0021b;

// Account color:
$wild-sand: #f6f5f3;
$sea-fog: #dfdcd6;

// Transparent colors
$gray-10: rgba($primary, 0.1);
$gray-20: rgba($primary, 0.2);
$gray-30: rgba($primary, 0.3);
$gray-40: rgba($primary, 0.4);
$gray-50: rgba($primary, 0.5);
$gray-60: rgba($primary, 0.6);
$gray-70: rgba($primary, 0.7);
$gray-80: rgba($primary, 0.8);
$gray-90: rgba($primary, 0.9);
$ground-worthy-33:rgba(132,116,55, 0.33);
$overlay-global-color: rgba($black, 0.32);
$overlay-regional-color: rgba($floral-white, 0.75);
$light-gray-32: rgba($light-gray, 0.32);

// SG colors :
$charcoal: #424242;
$citrus: #84bd00;
$gainsboro: #e0e0e0;
$night-rider: #333;
$sangria: #990000;
$swatch-types: black #000000, beige #f5f5dc, blue blue, purple purple, red red, brown #783201, green green, grey #8f979d, pink #fe249a, orange orange, white #ffffff, yellow #ffff00, navy navy;

// fonts
$sans-serif: 'LOccitaneSans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$serif: 'LOccitaneSerif', ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
$icons: 'occ-icons';

// font weights
$light: 200;
$regular: 400;
$medium: 500;
$bold: 600;

// letter spacing
$tracking-wide: 0.04rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 23.4375em, // 375px
    md: 48em, // 768px
    lg: 64em, // 1024px
    xl: 80em // 1280px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 57.5rem,
    md: 67rem,
    lg: 86rem,
    xl: 115rem
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           1.6rem !default;
$small-gutter-width:          0.8rem !default;

// media queries mixins
@mixin media($b) {
    @if $b == 'phone-small' {
        @include media-breakpoint-down(xs) {
            @content;
        }
    } @else if $b == 'phone' {
        @include media-breakpoint-down(sm) {
            @content;
        }
    } @else if $b == 'phone-tablet' {
        @include media-breakpoint-down(md) {
            @content;
        }
    } @else if $b == 'phone-tablet-desktop-small' {
        @include media-breakpoint-down(lg) {
            @content;
        }
    } @else if $b == 'tablet-only' or $b == 'tablet' {
        @include media-breakpoint-only(md) {
            @content;
        }
    } @else if $b == 'desktop-small-only' or $b == 'desktop-small' {
        @include media-breakpoint-only(lg) {
            @content;
        }
    } @else if $b == 'phone-tablet-desktop' {
        @include media-breakpoint-up(sm) {
            @content;
        }
    } @else if $b == 'tablet-desktop' {
        @include media-breakpoint-up(md) {
            @content;
        }
    } @else if $b == 'desktop' {
        @include media-breakpoint-up(lg) {
            @content;
        }
    } @else if $b == 'desktop-large' {
        @include media-breakpoint-up(xl) {
            @content;
        }
    }
    @else {
        @content;
    }
}

// Icon size should correspond to icon file to maintain uniformity of appearance
$icon-sizes: (
    2xl: 6.4rem,    // 'icon'
    xl: 4rem,       // 'icon'
    lg: 3.2rem,     // 'icon'
    base: 2.4rem,   // 'icon'
    md: 1.6rem,     // 'icon-m'
    sm: 1.2rem,     // 'icon-s'
    xs: 0.8rem      // 'icon-xs'
) !default;

// @include icon-size($name) - optional argument $name, default : 'base' 2.4rem
@mixin icon-size($name:base) {
    $size: map-get($icon-sizes, $name);
    font-size: $size;
}

// Icon-size class name utility (.icon-size-2xl, ...)
@each $name, $value in $icon-sizes {
    .icon-size-#{$name} {
        @include icon-size($name);
    }
}


// transition easing
$cubic: cubic-bezier(.7,0,.3,1);
$ease-in: cubic-bezier(0.4, 0, 1, 1);
$ease-out: cubic-bezier(0, 0, 0.2, 1);
$inertia: cubic-bezier(.73,-0.57,.62,1.55);
$inertia-in: cubic-bezier(.84,-0.44,.35,.92);

// Header Font size
$header1rstLvl: 1.2rem;

// Search overlay transition constant
$trans-duration: .3s;

// border
$border-thin: 0.1rem solid $iron;

// global classes
@mixin visually-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.1rem;
}

.visually-hidden {
    @include visually-hidden();
}

@mixin visually-visible() {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    display: inline-block;
    clip: auto;
}

@mixin sr-only() {
    &.sr-only,
    .sr-only {
        border: 0 !important;
        clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem) !important;
        -webkit-clip-path: inset(50%) !important;
                clip-path: inset(50%) !important;
        height: 0.1rem !important;
        margin: -0.1rem !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 0.1rem !important;
        white-space: nowrap !important;
    }
}

// MIXIN SCROLLBAR
@mixin scrollbar() {
    // IE
    scrollbar-base-color: rgba(137, 137, 137, 1);
    scrollbar-face-color: rgba(137, 137, 137, 1);
    scrollbar-highlight-color: $floral-white;
    scrollbar-track-color: $floral-white;
    scrollbar-arrow-color: $floral-white;
    scrollbar-shadow-color: $floral-white;
    scrollbar-dark-shadow-color: $floral-white;

    // STANDARD (FF64+)
    scrollbar-width: thin;
    scrollbar-color: rgba(35, 35, 35, 1) transparent;

    // WEBKIT
    &::-webkit-scrollbar {
        width: 0.6rem;
        height: 0.4rem;
        background: rgba(0, 0, 0, 0);
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 0.3rem;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(137, 137, 137, 1);
        border-radius: 0.3rem;

        &:hover {
            background: rgba(35, 35, 35, 1);
        }
    }
}

@keyframes skeletonLoading {
    0% {
        transform: translateX(-100%)
    }
    35%, 100% {
        transform: translateX(100%)
    }
}

@mixin skeleton() {
    background: $narvik-true;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-image: linear-gradient(90deg, #fff0 0, rgba(255, 255, 255, 0.64) 10rem, #fff0 20rem);
        animation: skeletonLoading 2s linear infinite;
    }
}

/* SWIPER */
@mixin swiper-scrollbar-position-calc($gutter) {
    left: $gutter;
    @if $gutter == 0 {
        width: 100%;
    } @else {
        width: calc(100% - 2 * #{$gutter});
    }
}

@mixin swiper-scrollbar-position($bp: false, $gutter: $grid-gutter-width) {
    @if $bp {
        @include media($bp) {
            @include swiper-scrollbar-position-calc($gutter);
        }
    } @else {
        @include swiper-scrollbar-position-calc($gutter);
    }
}
