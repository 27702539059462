// sans 200
@font-face {
    font-family: 'LOccitaneSans';
    src: url('../fonts/LOccitaneSans-Light.woff2') format('woff2'),
        url('../fonts/LOccitaneSans-Light.woff') format('woff');
    font-weight: $light;
    font-style: normal;
    font-display: swap;
}

// sans 400
@font-face {
    font-family: 'LOccitaneSans';
    src: url('../fonts/LOccitaneSans-Regular.woff2') format('woff2'),
        url('../fonts/LOccitaneSans-Regular.woff') format('woff');
    font-weight: $regular;
    font-style: normal;
    font-display: swap;
}

// sans 500
@font-face {
    font-family: 'LOccitaneSans';
    src: url('../fonts/LOccitaneSans-Medium.woff2') format('woff2'),
        url('../fonts/LOccitaneSans-Medium.woff') format('woff');
    font-weight: $medium;
    font-style: normal;
    font-display: swap;
}

// sans 600
@font-face {
    font-family: 'LOccitaneSans';
    src: url('../fonts/LOccitaneSans-Bold.woff2') format('woff2'),
        url('../fonts/LOccitaneSans-Bold.woff') format('woff');
    font-weight: $bold;
    font-style: normal;
    font-display: swap;
}

// serif 400
@font-face {
    font-family: 'LOccitaneSerif';
    src: url('../fonts/LOccitaneSerif-Regular.woff2') format('woff2'),
        url('../fonts/LOccitaneSerif-Regular.woff') format('woff');
    font-weight: $regular;
    font-style: normal;
    font-display: swap;
}

// serif 600
@font-face {
    font-family: 'LOccitaneSerif';
    src: url('../fonts/LOccitaneSerif-Bold.woff2') format('woff2'),
        url('../fonts/LOccitaneSerif-Bold.woff') format('woff');
    font-weight: $bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'occ-icons';
    src: url('../fonts/occ-icons.woff2') format('woff2'),
        url('../fonts/occ-icons.woff') format('woff');
    font-weight: $regular;
    font-style: normal;
    font-display: block;
}
