@import "functions";
@import "variables";
@import "icons";
@import "grid";
@import "breakpoints";
@import "form_components";
@import "fonts";
@import "buttons";

button {
    font-family: LOccitaneSans,system-ui,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}