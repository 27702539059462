@import "../../assets/theme/index.scss";

.jobCard {
    display: flex;
    align-items: center;
    padding: 2rem;
    border: 1px solid $iron;

    .jobCard__text {

        p {
            color: $primary;
        }
        strong {
            font-weight: 700;
            font-size: 1.75rem;
        }

        span {
            display: flex;
            align-items: center;
            color: $light-gray;
            font-weight: 700;
            font-size: 1.25rem;
            text-transform: uppercase;
        

            i {
                margin-right: 0.8rem;
            }
        }
    }
}